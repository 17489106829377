import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import gsap from 'gsap';
import './Accordion.scss';
import { FaqAccordionHome } from '../../utils/contentProvider';

function Accordion({ data }) {
  const [accordion, setAccordion] = useState(null);

  const accordionTrigger = useCallback(
    (value) => () => setAccordion((state) => (state === value ? null : value)),
    [],
  );

  useEffect(() => {
    const groups = gsap.utils.toArray('.accordion-group');
    const menus = gsap.utils.toArray('.accordion-menu');
    const menuToggles = groups.map(createAnimation);

    menus.forEach((menu) => {
      menu.addEventListener('click', () => toggleMenu(menu));
    });

    function toggleMenu(clickedMenu) {
      menuToggles.forEach((toggleFn) => toggleFn(clickedMenu));
    }

    function createAnimation(element) {
      const menu = element.querySelector('.accordion-menu');
      const box = element.querySelector('.accordion-content');

      gsap.set(box, { height: 'auto' });

      const animation = gsap
        .from(box, {
          height: 0,
          duration: 0.5,
          ease: 'power1.inOut',
        })
        .reverse();

      return function (clickedMenu) {
        if (clickedMenu === menu) {
          animation.reversed(!animation.reversed());
        } else {
          animation.reverse();
        }
      };
    }
  }, []);

  return (
    <div className="accordion">
      {(data || FaqAccordionHome).map(({ id, question, answer }) => (
        <div
          key={id}
          className={`accordion-group ${accordion === id ? 'open' : ''}`}
          id={id}>
          <button
            type="button"
            onClick={accordionTrigger(id)}
            className="accordion-menu">
            {question}

            <span className="close-icon"></span>
          </button>
          <div className="accordion-content">
            <div className="accordion-content-wrapper">{answer}</div>
          </div>
        </div>
      ))}
    </div>
  );
}

Accordion.defaultProps = {
  data: null,
};

Accordion.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      question: PropTypes.string,
      answer: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    }),
  ),
};
export default Accordion;
